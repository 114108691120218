'use strict'

import Modal from '@ui/Modal/component'
import type { ModalConfig, ModalType } from '@ui/Modal/component'

export default function (id: string, config: ModalConfig = {}): ModalType | undefined {
  const modalEl = document.getElementById(id)

  if (!modalEl) {
    return
  }

  return Modal(modalEl, config)
}
