import AccommodationCard from '@ui/Accommodation/Card/component'
import ProductCard from '@ui/Product/Card/component'
import BestOfferPriceDetailsOptionsDetails from '@ui/Accommodation/Card/BestOffer/PriceDetails/OptionsDetails/component'
import { isMobile } from 'assets/core/js/common'

import type { Options as AccommodationCardOptions } from '@ui/Accommodation/Card/component'
import type { Options as ProductCardOptions } from '@ui/Product/Card/component'

export default (el: HTMLElement, productCardOptions: ProductCardOptions, accommodationCardOptions: AccommodationCardOptions): void => {
  const productCard = el.querySelector<HTMLElement>('.product-card')

  if (productCard) {
    ProductCard(productCard, productCardOptions)

    const bestOfferPriceDetailsOptionsDetailsElement = productCard.querySelector<HTMLElement>(
      '.accommodation-card-best-offer-price-details-options-details'
    )
    if (bestOfferPriceDetailsOptionsDetailsElement) {
      BestOfferPriceDetailsOptionsDetails(bestOfferPriceDetailsOptionsDetailsElement)
    }
  }

  const accommodations = el.querySelectorAll<HTMLElement>('.accommodation-card')

  accommodations.forEach((accommodationCard) => {
    AccommodationCard(accommodationCard, accommodationCardOptions)

    const bestOfferPriceDetailsOptionsDetailsElement = accommodationCard.querySelector<HTMLElement>(
      '.accommodation-card-best-offer-price-details-options-details'
    )
    if (bestOfferPriceDetailsOptionsDetailsElement) {
      BestOfferPriceDetailsOptionsDetails(bestOfferPriceDetailsOptionsDetailsElement)
    }
  })

  const accommodationsListEl = el.querySelector<HTMLElement>('.accommodations-list')

  el.querySelector<HTMLLinkElement>('.view-all-accommodations')?.addEventListener('click', function (e) {
    e.preventDefault()

    if (accommodationsListEl && el.hasAttribute('data-with-all-accommodations')) {
      accommodationsListEl.setAttribute('data-opened', accommodationsListEl.getAttribute('data-opened') === 'true' ? 'false' : 'true')
      return
    }

    if (!isMobile()) {
      this.href && window.open(this.href)
    } else {
      document.location.href = this.href
    }
  })
}
