'use strict'

import tooltipV2 from '@ui/Tooltip/component'

export default function (element: HTMLElement): void {
  const labelEl = element.querySelector<HTMLElement>('.label')
  const tooltipEl = element.querySelector<HTMLElement>('.tooltip')

  if (labelEl && tooltipEl) {
    tooltipV2(labelEl, tooltipEl)
  }
}
