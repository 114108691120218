'use strict'

import ModalPlan from '@ui/Modal/Plan/component'
import tooltipV2 from '@ui/Tooltip/component'
import CheckoutAccess from '@ui/Accommodation/CheckoutAccess/component'
import Flags from '@ui/Flags/component'
import ImagesSlider from '@ui/ImagesSlider/component'
import ElementPropertiesManager from 'assets/core/js/module/elementPropertiesManager'
import BookingReward from '@ui/Accommodation/Offer/BookingReward/component'

import type { ModalType } from '@ui/Modal/component'
import * as common from 'assets/core/js/common'

export interface Options {
  onClickCard?: (card: HTMLElement) => void
  onClickPropertiesViewMoreBtn?: (card: HTMLElement) => void
  onClickImage?: (card: HTMLElement, image: HTMLImageElement) => void
  onClickDetailsBtn?: (card: HTMLElement) => void
  onClickBestOfferBtn?: (card: HTMLElement, button: HTMLButtonElement) => void
  onClickChangeDateBtn?: (card: HTMLElement, button: HTMLButtonElement) => void
  onClickPlanBtn?: (card: HTMLElement, button: HTMLButtonElement) => void
  enableImagesSlider?: boolean
}

const initPlanModal = function (card: HTMLElement, fn?: Options['onClickPlanBtn']): void {
  if (ElementPropertiesManager.hasProperty(card, 'planModal')) {
    return
  }

  const buttonEl = card.querySelector<HTMLButtonElement>('.accommodation-card__plan-button')

  if (!buttonEl) {
    return
  }

  const id = buttonEl.getAttribute('data-modal-target')

  if (!id) {
    return
  }

  let modal: ModalType | undefined

  buttonEl.addEventListener('click', () => {
    if (!modal) {
      modal = ModalPlan(id, {
        onOpen: () => {
          if (fn) {
            fn(card, buttonEl)
          }
        },
      })
    }

    modal?.open()
  })

  ElementPropertiesManager.addProperty(card, 'planModal', modal)
}

export const initImagesSlider = function (card: HTMLElement): void {
  const imagesSliderEl = card.querySelector<HTMLElement>('.accommodation-card__images .images-slider')

  if (!imagesSliderEl) {
    return
  }

  ImagesSlider(imagesSliderEl)
}

const initClickCard = function (card: HTMLElement, fn?: Options['onClickCard']): void {
  const preventOpenClasses = [
    'slider-navigation',
    'slider-pagination',
    'accommodation-card__images',
    'accommodation-card__details-link-button',
    'accommodation-card__offer-actions button',
    'accommodation-card__offer-price-info',
    'accommodation-card__plan-button',
  ]

  const preventOpenClassesDesktop = [
    'slider-navigation',
    'slider-pagination',
    'accommodation-card__images',
    'accommodation-card__details-link-button',
    'accommodation-card__offer-actions button',
    'accommodation-card__plan-button',
    'accommodation-card__offer',
  ]

  if (!fn) {
    return
  }

  card.addEventListener('click', function (this: HTMLElement, e) {
    const target = e.target as HTMLElement
    const preventIfClasses = common.isDesktop() ? preventOpenClassesDesktop : preventOpenClasses

    if (
      preventIfClasses.filter(
        (val) => target.classList.contains(val) || target.closest(`.${val}`) !== null || document.querySelector(`.${val}`)?.contains(target)
      ).length === 0
    ) {
      e.preventDefault()
      e.stopPropagation()

      fn(card)
    }
  })
}

const initClickPropertiesViewMoreBtn = function (card: HTMLElement, fn?: Options['onClickBestOfferBtn']): void {
  const buttonEl = card.querySelector<HTMLButtonElement>('.accommodation-card__properties-list button')

  if (!buttonEl || !fn) {
    return
  }

  buttonEl.addEventListener('click', (e) => {
    e.stopPropagation()
    fn(card, buttonEl)
  })
}

const initClickBestOfferBtn = function (card: HTMLElement, fn?: Options['onClickBestOfferBtn']): void {
  const buttonEl = card.querySelector<HTMLButtonElement>('.accommodation-card__offer-actions button')

  if (!buttonEl || !fn) {
    return
  }

  buttonEl.addEventListener('click', (e) => {
    e.stopPropagation()
    fn(card, buttonEl)
  })
}

const initClickDetailsBtn = function (card: HTMLElement, fn?: Options['onClickDetailsBtn']): void {
  const buttonEls = card.querySelectorAll('.accommodation-card__details-link-button')

  if (!buttonEls || !fn) {
    return
  }

  buttonEls.forEach((el) => {
    el.addEventListener('click', (e) => {
      e.stopPropagation()
      fn(card)
    })
  })
}

const initClickImage = function (card: HTMLElement, fn?: Options['onClickImage']): void {
  if (!fn) {
    return
  }

  card.querySelectorAll<HTMLImageElement>('.accommodation-card__images .slider .slide img').forEach((el) => {
    el.addEventListener('click', () => {
      fn(card, el)
    })
  })
}

const initClickChangeDateBtn = function (card: HTMLElement, fn?: Options['onClickChangeDateBtn']): void {
  const buttonEl = card.querySelector<HTMLButtonElement>('.accommodation-card__offer-message-button')

  if (!buttonEl || !fn) {
    return
  }

  buttonEl.addEventListener('click', (e) => {
    e.stopPropagation()
    fn(card, buttonEl)
  })
}

const initCheckoutAccess = function (card: HTMLElement): void {
  const checkOfferForm = card.querySelector<HTMLFormElement>('form[data-check-offer-id]')

  if (!checkOfferForm) {
    return
  }

  CheckoutAccess(checkOfferForm)
}

const initTooltips = function (rootEl: HTMLElement): void {
  const offerDiscountInfoEl = rootEl.querySelector<HTMLElement>('.accommodation-card__offer-discount-info-text')
  const offerDiscountInfoTooltipEl = rootEl.querySelector<HTMLElement>('.accommodation-card__offer-discount-info-tooltip')

  if (offerDiscountInfoEl && offerDiscountInfoTooltipEl) {
    tooltipV2(offerDiscountInfoEl, offerDiscountInfoTooltipEl)
  }

  Flags(rootEl)
}

export default function (el: HTMLElement, options: Options = {}): void {
  if (el.hasAttribute('data-init')) {
    return
  }

  initClickCard(el, options.onClickCard)
  initClickPropertiesViewMoreBtn(el, options.onClickPropertiesViewMoreBtn)
  initClickImage(el, options.onClickImage)
  initClickBestOfferBtn(el, options.onClickBestOfferBtn)
  initClickDetailsBtn(el, options.onClickDetailsBtn)
  initClickChangeDateBtn(el, options.onClickChangeDateBtn)
  initCheckoutAccess(el)

  el.setAttribute('data-init', 'true')

  if (!options.enableImagesSlider) {
    options.enableImagesSlider = true
  }

  // defer the following non-critical calls
  setTimeout(() => {
    options.enableImagesSlider === true && initImagesSlider(el)
    initPlanModal(el, options.onClickPlanBtn)
    // This component takes the responsibility of initializing the
    // Flags tooltips directly, in addition to its own tooltips.
    initTooltips(el)

    const bookingRewardElement = el.querySelector<HTMLElement>('.accommodation-offer-booking-reward')
    if (bookingRewardElement) {
      BookingReward(bookingRewardElement)
    }
  }, 0)
}
