import Modal from '@ui/Modal/component'
import { hideLoading, showLoading } from '@design/objects/button/twig/assets/loading'
import type { ModalType } from '@ui/Modal/component'

interface Handler {
  cancel: () => void
  setAsk: (ask: XMLHttpRequest | null) => void
  setValidate: (validate: XMLHttpRequest | null) => void
  setTimeout: (timeout: ReturnType<typeof setTimeout> | null) => void
}

enum Status {
  loading = 'loading',
  error = 'error',
}

const setStatus = (modal: ModalType, status: Status): void => {
  const statusElement = modal.element.querySelector('[data-status]') as HTMLElement

  statusElement.setAttribute('data-status', status)
}

const createHandler = (): Handler => {
  const handler: {
    ask: XMLHttpRequest | null
    validate: XMLHttpRequest | null
    timeout: ReturnType<typeof setTimeout> | null
  } = {
    ask: null,
    validate: null,
    timeout: null,
  }

  return {
    cancel: function () {
      if (handler.ask !== null) {
        handler.ask.abort()
        handler.ask = null
      }
      if (handler.validate !== null) {
        handler.validate.abort()
        handler.validate = null
      }
      if (handler.timeout !== null) {
        clearTimeout(handler.timeout)
        handler.timeout = null
      }
    },
    setAsk: function (ask) {
      handler.ask = ask
    },
    setValidate: function (validate) {
      handler.validate = validate
    },
    setTimeout: function (timeout) {
      handler.timeout = timeout
    },
  }
}

const validateDraft = (
  validateUrl: string,
  draftData: string,
  timeout: number,
  handler: Handler,
  onSuccess?: (redirectUrl: string) => void,
  onError?: () => void
): void => {
  handler.setTimeout(null)
  // Regularly send validate requests, until receiving a redirect URL.
  const request = new XMLHttpRequest()
  handler.setValidate(request)
  request.open('POST', validateUrl)
  request.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

  request.onreadystatechange = () => {
    if (request.readyState === 4 && request.status === 200 && typeof request.response === 'string') {
      handler.setValidate(null)
      const data = JSON.parse(request.response)
      if (!data || !Object.prototype.hasOwnProperty.call(data, 'redirect')) {
        onError && onError()

        return
      }
      const response = data as { redirect: string | null }

      if (response.redirect === null) {
        handler.setTimeout(
          setTimeout(() => {
            validateDraft(validateUrl, draftData, timeout, handler, onSuccess, onError)
          }, timeout)
        )

        return
      }

      onSuccess && onSuccess(response.redirect)
    } else if (request.readyState === 4) {
      handler.setValidate(null)
      onError && onError()
    }
  }

  const formData = new FormData()
  formData.append('draftData', draftData)
  request.send(formData)
}

export const Client = (form: HTMLFormElement, timeout = 500, onSuccess?: (redirectUrl: string) => void, onError?: () => void): Handler => {
  const handler = createHandler()
  const formData = new FormData(form)

  const request = new XMLHttpRequest()
  handler.setAsk(request)
  request.open('POST', form.action)
  request.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

  request.onreadystatechange = () => {
    // XMLHttpRequest.DONE === 4. We use the value directly for Jest.
    if (request.readyState === 4 && request.status === 200 && typeof request.response === 'string') {
      handler.setAsk(null)
      const data = JSON.parse(request.response)

      if (!data || (!Object.prototype.hasOwnProperty.call(data, 'draftData') && !Object.prototype.hasOwnProperty.call(data, 'redirect'))) {
        onError && onError()

        return
      }

      if (Object.prototype.hasOwnProperty.call(data, 'redirect')) {
        const response = data as { redirect: string }

        onSuccess && onSuccess(response.redirect)

        return
      }

      const response = data as { draftData: string }

      validateDraft(form.getAttribute('data-validate-url') as string, response.draftData, timeout, handler, onSuccess, onError)
    } else if (request.readyState === 4) {
      handler.setAsk(null)
      onError && onError()
    }
  }

  request.send(formData)

  return handler
}

export default function (form: HTMLFormElement, timeout = 500, onSuccess?: (redirectUrl: string) => void, onError?: () => void): void {
  const hasValidateUrl = form.hasAttribute('data-validate-url')
  const buttonEl = form.querySelector<HTMLButtonElement>('[type="submit"]')
  let modal: ModalType | null

  if (!buttonEl) {
    return
  }

  if (hasValidateUrl) {
    const offerId = form.getAttribute('data-check-offer-id')

    if (!offerId) {
      return
    }

    const modalEl = form.nextElementSibling?.classList.contains('accommodation-checkout-access__modal')
      ? (form.nextElementSibling as HTMLElement)
      : null

    if (!modalEl) {
      return
    }

    let handler: Handler | undefined
    modal = Modal(modalEl, {
      onClose: () => {
        handler?.cancel()
        hideLoading(buttonEl)
      },
    })

    const showError = (): void => {
      modal && setStatus(modal, Status.error)
    }

    if (!onSuccess) {
      onSuccess = (redirectUrl) => {
        window.location.assign(redirectUrl)
      }
    }

    if (!onError) {
      onError = () => {
        showError()
      }
    }

    buttonEl.addEventListener('click', () => {
      modal && modal.open()
      modal && setStatus(modal, Status.loading)
      handler = Client(form, timeout, onSuccess, onError)
    })
  }

  form.addEventListener('submit', function (event) {
    if (hasValidateUrl) {
      event.preventDefault()
    }

    showLoading(buttonEl)
  })

  window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
      if (hasValidateUrl && modal) {
        modal.close()
      }

      hideLoading(buttonEl)
    }
  })
}
