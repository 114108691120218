'use strict'

import * as coreCommon from 'assets/core/js/common'
import productFavorite from 'assets/core/js/page/search/product/favorite'
import sliders from 'assets/themes/campings/js/page/search/imagesSliders'
import bookingReward from 'assets/themes/campings/js/page/search/bookingReward'

const fixedDatesThematic = {
  init: function () {
    this.handleDepartureDatesMenu()
  },

  handleDepartureDatesMenu: function () {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    self.cacheCurrentTab()
    const queryString = location.href.includes('?') ? location.href.split('?').pop() : ''
    const urlSearchParams = new URLSearchParams(queryString)
    urlSearchParams.delete('checkInDate')

    // links menu for desktop and tablet
    document.querySelectorAll('.departure-date-groups ul span[data-path]').forEach(function (link) {
      link.addEventListener('click', function (this: HTMLElement, e) {
        e.preventDefault()
        self.updateDepartureDatesMenu(this)
        self.loading()

        let existingQueryParams = ''
        Array.from(urlSearchParams.entries()).map((item) => (existingQueryParams += '&' + item[0] + '=' + item[1]))

        self.updateTab(link.getAttribute('data-path') + '?checkInDate=' + link.getAttribute('data-date') + existingQueryParams)
      })
    })

    // select for mobile
    document.querySelector('.form-departure-date-groups select')?.addEventListener('change', function (this: HTMLSelectElement) {
      self.loading()

      let existingQueryParams = ''
      Array.from(urlSearchParams.entries()).map((item) => (existingQueryParams += '&' + item[0] + '=' + item[1]))

      self.updateTab(this.getAttribute('data-path') + '?checkInDate=' + this.value + existingQueryParams)
    })
  },

  loading: function () {
    document.body.scrollTop = 0
    document.getElementById('error-availabilities')?.classList.add('no-availabilities__hidden')
    document.querySelector('.departure-date-groups')?.scrollIntoView({
      block: 'start',
      inline: 'nearest',
      behavior: 'smooth',
    })

    if (document.getElementById('results')) {
      document.getElementById('results')?.classList.add('results--hidden')
      document.getElementById('results-loading')?.classList.add('loading--visible')
    }
  },

  updateTab: function (url: string) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this

    if (document.getElementById('results')) {
      if (document.querySelector('[data-cached-for="' + url + '"]') !== null) {
        setTimeout(() => {
          self.displayTab(url)
        }, 400)
      } else {
        const xhr = coreCommon.createXhr('GET', url)

        xhr.onreadystatechange = () => {
          if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
            const content = xhr.response as string

            const newList = document.createElement('div')
            newList.setAttribute('data-cached-for', url)
            newList.className = document.getElementById('results-list')?.className as string
            newList.innerHTML = content
            document.getElementById('results')?.appendChild(newList)

            self.displayTab(url)

            coreCommon.initLazyLoading('.dca-results__list img.lazy')

            productFavorite()
            bookingReward()
          }
        }

        xhr.send(null)
      }
    }
  },

  updateDepartureDatesMenu: function (clickedLink: HTMLElement) {
    document.querySelectorAll('.departure-date-groups ul li').forEach(function (link) {
      if (link.classList.contains('active')) {
        link.classList.remove('active')
      }
    })

    clickedLink.parentElement?.classList.add('active')
  },

  displayTab: function (url: string) {
    document.querySelectorAll('#results > div').forEach(function (tab) {
      if (tab.classList.contains('cached-tab') === false) {
        tab.classList.add('cached-tab')
      }
    })

    const errorAvailabilitiesEl = document.getElementById('error-availabilities')

    errorAvailabilitiesEl?.classList.add('no-availabilities__hidden')
    document.getElementById('results-list')?.removeAttribute('id')

    const tabToShow = document.querySelector('[data-cached-for="' + url + '"]')
    tabToShow?.classList.remove('cached-tab')
    tabToShow?.setAttribute('id', 'results-list')
    tabToShow?.classList.add('results__list')

    document.getElementById('results')?.classList.remove('results--hidden')
    document.getElementById('results-loading')?.classList.remove('loading--visible')

    if (document.getElementById('results-list')?.innerHTML.trim() === '') {
      errorAvailabilitiesEl?.classList.remove('no-availabilities__hidden')
    }

    window.history.replaceState(null, '', url)
    sliders.init()

    const pagination = document.querySelector('.dca-pagination')
    const paginationActiveEl = pagination?.querySelector('.dca-pagination__item--active')
    if (pagination && paginationActiveEl) {
      paginationActiveEl.classList.remove('dca-pagination__item--active')
      pagination.querySelector('.dca-pagination__item-first')?.classList.add('dca-pagination__item--active')
    }
  },

  cacheCurrentTab: function () {
    if (document.querySelector('.departure-date-groups ul li.active span[data-path]') !== null) {
      const link = document.querySelector('.departure-date-groups ul li.active span[data-path]')
      const url = link?.getAttribute('data-path') + '?checkInDate=' + link?.getAttribute('data-date')

      if (document.getElementById('results-list')) {
        document.getElementById('results-list')?.setAttribute('data-cached-for', url)
      }
    }
  },
}

export default fixedDatesThematic
